import React, {useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {Stack, Grid, Container, Button, Typography, Link, Modal, Box, Alert} from "@mui/material";
import Particles from "./Particles";
import PaymentModal from "./PaymentModal";
import {useTexts} from "./TextContext";


// Service component definition
const Service = ({service, child, childSuccess, url, payParams, handleOpenSuccess}) => {
    const {texts} = useTexts(); // Use custom text context
    const navigate = useNavigate(); // React Router navigation
    const [value, setValue] = useState(0); // State for transaction value
    const [status, setStatus] = useState(''); // State for transaction status
    const [requisites, setRequisites] = useState(''); // State for requisites
    const [openPayment, setOpenPayment] = useState(false); // State for payment modal
    const [openStatus, setOpenStatus] = useState(false); // State for status modal

    // Function to handle the transaction
    const transaction = async () => {
        try {
            // Calculate transaction data
            const transactionsData = await calc();
            if (transactionsData) {
                setValue(transactionsData.value);
                setRequisites(transactionsData.requisites);
                setOpenPayment(true);

                if (process.env.DEBUG) {
                    console.log("Pay: ", transactionsData);
                }

                let transactionId = await transactionInit(transactionsData, 'pay', process.env.PAY_TIMEOUT);
                let transactionStatus = await transactionCheck(transactionId, 'pay')

                if (transactionStatus === 'success') {
                    setStatus("refill");
                    setOpenPayment(false);
                    setOpenStatus(true);

                    if (service === 'swift') {
                        if (process.env.DEBUG) {
                            console.log("Refill: ", transactionsData);
                        }

                        transactionsData['value'] = transactionsData['service']['UAH']
                        let transactionId = await transactionInit(transactionsData, 'refill', process.env.REFILL_TIMEOUT);
                        transactionStatus = await transactionCheck(transactionId, 'refill')
                    }
                }
                if (transactionStatus === 'success') {
                    if (process.env.DEBUG) {
                        console.log("Success: ", transactionsData);
                    }

                    let params = {};
                    if (service === "vpn") {
                        params = {
                            "period": transactionsData["service"]["period"]
                        };
                    }
                    let product = await get_product(params);
                    setStatus("success");
                    handleOpenSuccess(product);
                    setOpenStatus(true);

                    await _sendOKLogMessage(transactionsData);
                }
                if (transactionStatus === 'timeout') {
                    setStatus("timeout");
                    setOpenPayment(false);
                    setOpenStatus(true);
                }
                if (transactionStatus === 'error') {
                    setStatus("error");
                    setOpenPayment(false);
                    setOpenStatus(true);
                }
            } else {
                setStatus("");
                setOpenStatus(true);
                setOpenPayment(false);
            }
        } catch (error) {
            if (process.env.DEBUG) {
                console.error("Error Transactions:", error);
            }
            setStatus("");
            setOpenStatus(true);
        }
    };

    // Function to calculate transaction data
    const calc = async () => {
        try {
            const response = await axios.get(`${url}/calc`, {
                params: {...payParams}
            });
            if (process.env.DEBUG) {
                console.log("Fetching payment details:", response);
            }
            setStatus('pay');
            return response.data;
        } catch (error) {
            if (process.env.DEBUG) {
                console.error("Error Calc:", error);
            }
        }
    };

    const transactionInit = async (transactionsData, queue, timeout) => {
        try {
            const response = await axios.post(`/api/payment/transaction`, transactionsData,
                {
                    params: {
                        'queue': queue,
                        'timeout': timeout,
                    }
                });
            if (process.env.DEBUG) {
                console.log("Transaction init ID: ", response);
            }
            return response.data;
        } catch (error) {
            if (process.env.DEBUG) {
                console.error("Error Fetch Transaction ID:", error);
            }
        }
    }

    const getTransactionStatus = async (transactionId) => {
        try {
            const response = await axios.get(`/api/payment/transaction`,
                {
                    params: {
                        'transaction_id': transactionId
                    }
                });
            if (process.env.DEBUG) {
                console.log("Transaction (ID: ", transactionId, ") status: ", response);
            }
            return response.data;
        } catch (error) {
            if (process.env.DEBUG) {
                console.error("Error Pay:", error);
            }
        }
    }

    const transactionCheck = async (transactionId, queue) => {
        while (true) {
            await new Promise(resolve => setTimeout(resolve, process.env.SLEEP_PAYMENT * 1000));
            let status = await getTransactionStatus(transactionId);

            if (status !== null) {
                if (status === -1) {
                    setStatus('error')
                    return 'error'
                } else if (!status) {
                    if (queue === 'pay') {
                        setStatus('timeout')
                        return 'timeout'
                    } else {
                        setStatus('error')
                        return 'error'
                    }
                } else if (status) {
                    setStatus('success')
                    return 'success'
                }
                return -1;
            }
        }
    }

    // Function to fetch the product
    const get_product = async (params) => {
        try {
            const response = await axios.get(`${url}/get_product`, {params: {...params}});
            return response.data;
        } catch (error) {
            if (process.env.DEBUG) {
                console.error("Error fetch product:", error);
            }
        }
    };

    // Send OK log message
    const _sendOKLogMessage = async (transactionsData) => {
        try {
            await axios.post(`/api/payment/ok`, transactionsData);

        } catch (error) {
            if (process.env.DEBUG) {
                console.error("Error Send OK log message:", error);
            }
        }
    }

    return (
        <>
            <Particles/>
            <Container maxWidth="sm" sx={{height: "100%"}}>
                <Grid container justifyContent="center" alignContent="center" sx={{height: "100%"}}>
                    <Stack data-window justifyContent="space-between" alignItems="center">
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid xl>
                                <Typography component="h1" variant="h4">{texts[service]["name"]}</Typography>
                            </Grid>
                            <Grid xl="auto">
                                <Link onClick={() => {
                                    navigate('/')
                                }} href="#" underline="none" sx={{"&:hover img": {transform: "rotate(90deg)"}}}>
                                    <img alt="close" src="/images/close.webp"
                                         style={{transition: "transform 0.5s ease-in-out"}}/>
                                </Link>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="center" alignItems="center" gap="3rem">
                            <Grid item xs={12} sm>{child}</Grid>
                            <Grid item xs="auto">
                                <Button onClick={transaction} variant="contained">{texts["payment"]["handle"]}</Button>
                            </Grid>
                        </Grid>
                    </Stack>
                </Grid>
                <PaymentModal open={openPayment} onClose={() => {
                    setOpenPayment(false)
                }} value={value} requisites={requisites}/>
                <Modal open={openStatus} onClose={() => {
                    if (status !== "refill") {
                        setOpenStatus(false);
                    }
                }}>
                    <Box sx={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                        {status === "success" ? (
                            childSuccess
                        ) : status === "refill" ? (
                            <Alert severity="success">{texts["payment"]["success"]}</Alert>
                        ) : status === "timeout" ? (
                            <Alert severity="info">{texts["payment"]["info"]}</Alert>
                        ) : status === "error" ? (
                            <Alert severity="error"><Typography dangerouslySetInnerHTML={{__html: texts["payment"]["error"]}}/></Alert>
                        ) : (
                            <Alert severity="warning"><Typography dangerouslySetInnerHTML={{__html: texts["payment"]["warning"]}}/></Alert>
                        )}
                    </Box>
                </Modal>
            </Container>
        </>
    );
};

export default Service;
